import React from "react";
import { AppBar, Box, Toolbar, Container, Button, useScrollTrigger } from "@mui/material";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { pages } from '../const';

function ElevationScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
    style: {
      // backgroundColor: trigger ? 'rgb(20, 20, 20)' : 'rgb(14, 14, 14)',
      // boxShadow: trigger ? '0px 4px 10px rgba(0, 0, 0, 0.2)' : 'none', 
      backgroundColor: trigger ? 'rgba(33, 33, 33, 0.95)' : 'rgba(18, 18, 18, 1)', // Dark gray background when scrolled
      boxShadow: trigger ? '0px 4px 12px rgba(0, 0, 0, 0.5)' : 'none', // Slightly elevated box shadow when scrolled
    }
  });
}

function Navbar() {
  return (
    <ElevationScroll>
      <AppBar sx={{ backgroundColor: 'rgb(14, 14, 14)' }}>
        <Container
          maxWidth="xl"
          sx={{
            flexGrow: 1,
            display: { xs: "none", sm: "flex", md: "flex" },
            justifyContent: 'flex-end',
            marginRight: '10px',
            width: '100%'
          }}>
          <Toolbar
            disableGutters
            sx={{
              width: { xs: "50%", sm: "90%", md: "65%" },
              justifyContent: 'space-around'
            }}
          >
            {/* mobile view - speed dial */}
            {/* Desktop and Tab view */}
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", sm: "flex", md: "flex" },
                justifyContent: 'space-around'
              }}
            >
              {pages.map((page, index) => (
                <AnchorLink key={index} href={'#' + page} style={{textDecoration:'none'}}>
                  <Button
                    key={index}
                    sx={{ my: 2, color: "white", display: "block", borderRadius: '10px', ':hover': { bgcolor: '#08FDD8', color: 'black', fontWeight: '600' } }}
                  >
                    {page}
                  </Button>
                </AnchorLink>
              ))}
              <Button
                href="https://drive.google.com/file/d/1G-mPDdDSmer5z8DofkGhkFAFUqQHRu3R/view?usp=sharing"
                sx={{ my: 2, display: "block", borderRadius: '10px', ':hover': { bgcolor: '#08FDD8', color: 'black', fontWeight: '600' } }}
                target="_blank"
              >
                Download Resume
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </ElevationScroll>
  );
}
export default Navbar;
