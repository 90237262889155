import { createTheme, responsiveFontSizes } from "@mui/material/styles";

export const themeOptions = {
    palette: {
        mode: "dark",
        primary: {
            main: "#fd2155",
        },
        secondary: {
            main: "#08fdd8",
        },
        divider: "rgba(33, 33, 33, 0.95)",
    },
    typography:{
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        h4: {
            fontSize: '2.2rem',
        },
        h6: {
            fontSize: '1.5rem',
        },
    }
};

let theme = createTheme(themeOptions);
theme = responsiveFontSizes(theme);

export default theme;