import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import Timeline from "@mui/lab/Timeline";
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Box } from '@mui/material';

function AnimatedTimelineContent({ children, isVisible }) {
  const animationProps = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? 'translateY(0)' : 'translateY(50px)',
    from: { opacity: 0, transform: 'translateY(50px)' },
  });

  return <animated.div style={animationProps}>{children}</animated.div>;
}

function MyTimeline({ events }) {
  const [visibleItems, setVisibleItems] = useState({});

  const handleEnter = (index) => {
    setVisibleItems((prev) => ({ ...prev, [index]: true }));
  };

  return (
    <Timeline position='alternate-reverse' sx={{padding:'0px'}}>
      {events.map((event, index) => {
        const isVisible = visibleItems[index];
        return (
          <TimelineItem key={index}>
            <TimelineSeparator>
              <TimelineDot
                style={{ backgroundColor: isVisible ? '#08fdd8' : undefined }}
              />
              {index !== events.length - 1 && (
                <TimelineConnector
                  style={{ backgroundColor: isVisible ? '#08fdd8' : undefined }}
                />
              )}
            </TimelineSeparator>
            <TimelineContent>
              <Waypoint
                onEnter={() => handleEnter(index)}
                bottomOffset="50%"
              >
                <Box>
                  <AnimatedTimelineContent isVisible={isVisible}>
                    <div>
                      <h4>{event.date}</h4>
                      <h3>{event.title}</h3>
                      <p>{event.description}</p>
                    </div>
                  </AnimatedTimelineContent>
                </Box>
              </Waypoint>
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
}

export default MyTimeline;
