import React from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import Box from '@mui/material/Box';

const Loader = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100vw',
                position: 'fixed',
                top: 0,
                left: 0,
                backgroundColor: 'rgb(14, 14, 14)',
                zIndex: 9999
            }}
        >
            <ScaleLoader color="#36D7B7" speedMultiplier={1.5} />
        </Box>
    );
};

export default Loader;