import React from 'react';
import { Box, SpeedDial, SpeedDialIcon, SpeedDialAction } from '@mui/material';
import { Home as HomeIcon, Person as PersonIcon, Description as DescriptionIcon, ContactMail as ContactMailIcon, FileDownload } from '@mui/icons-material';

const actions = [
    { icon: <HomeIcon />, name: 'Home', href: '#Home' },
    { icon: <PersonIcon />, name: 'About', href: '#About Me' },
    { icon: <DescriptionIcon />, name: 'Projects', href: '#My Projects' },
    { icon: <ContactMailIcon />, name: 'Contact', href: '#Contact Me' },
];

function MobileSpeedDial() {
    const handleActionClick = (link) => {
        window.location.href = link;
    };

    return (
        <Box
            sx={{
                display: { xs: 'flex', sm: 'none' }, // Shows only on mobile view
                position: 'fixed',
                bottom: 16,
                right: 16,
                zIndex: 1,
            }}
        >
            <SpeedDial
                ariaLabel="SpeedDial persistent tooltip"
                icon={<SpeedDialIcon />}
                direction="up"
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        tooltipOpen
                        onClick={() => handleActionClick(action.href)}
                    />
                ))}
                <SpeedDialAction
                    key={'resume'}
                    icon={<FileDownload />}
                    tooltipTitle={'Resume'}
                    tooltipOpen
                    onClick={() => window.open("https://drive.google.com/file/d/1G-mPDdDSmer5z8DofkGhkFAFUqQHRu3R/view?usp=sharing", '_blank').focus()}
                />
            </SpeedDial>
        </Box>
    );
}

export default MobileSpeedDial;