import React from "react";
import { Box, Container, Typography } from "@mui/material";
import MyImage from '../assets/p1-removebg3.webp';
import theme from "../theme";

function hero() {
    return (
        <Box
            id="Home"
            sx={{
                // border:'2px solid red',
                marginBottom: "30px",
                display: "flex",
                flexDirection: 'column-reverse',
                justifyContent: "center",
                marginTop: '50px',
                marginLeft: '10px',
                width: '90vw',
                [theme.breakpoints.up('sm')]: {
                    height: "80vh",
                    width: '97vw',
                    flexDirection: 'row',
                    marginTop: '0px',
                    marginLeft: '0px',
                },
                [theme.breakpoints.up('lg')]: {
                    width: '95vw',
                    height: '100vh',
                },
            }}
        >
            <Container
                sx={{
                    display: 'flex',
                    flexDirection:'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '5px',
                    [theme.breakpoints.up('sm')]: {
                        width: '60%',
                    },
                    [theme.breakpoints.up('lg')]: {
                        margin: '10px',
                        marginTop:'100px',
                    },
                }}
            >
                <Box
                    sx={{
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        padding:'0px',
                    }}
                >
                    <Box
                        className="desc1"
                        sx={{
                            // border:'2px solid red',
                            position: "relative",
                            top: '-50px',
                            left: '30px',
                            animation: 'fadeIn 1s ease-in-out',
                            [theme.breakpoints.up('sm')]: {
                                top: '-150',
                                left: '60'
                            },
                            [theme.breakpoints.up('lg')]: {
                                width: '100px',
                            },
                        }}
                    >
                        <Typography
                            variant="caption"
                            width='inherit'
                            display='block'
                            gutterBottom
                            sx={{
                                color:theme.palette.secondary.main,
                                fontSize: '0.6rem',
                                [[theme.breakpoints.up('sm')]]: {
                                    fontSize: '0.75rem'
                                },
                                [theme.breakpoints.up('lg')]: {
                                    fontSize: '1rem',
                                },
                            }}
                        >
                            That's me
                        </Typography>
                        <svg className="ml-3 -scale-x-1 -scale-y-1 rotate-45 transform relative" xmlns="http://www.w3.org/2000/svg" height="10" width="70" viewBox="0 0 123.85 12.57" color={theme.palette.secondary.main}>
                            <path d="M60.09.07A261.23,261.23,0,0,0,15.3,5.13C11.35,6.6,1.76,6.93,0,10.5a1.11,1.11,0,0,0,1.36.77C10.8,7.86,20.79,6.15,30.7,4.76l-.3,0c24.23-3.16,49-3.54,73.16.35a154,154,0,0,1,18.85,4.33,1.15,1.15,0,0,0,1.4-.79c-1.66-3.57-13-4.09-17.11-5.53C91.32.38,75.69-.23,60.09.07Z" fill="currentColor" />
                            <path d="M109.1.57c-.73,3.33,8.28,4.55,10.41,7.11,1.67,2.26,5.58,2.91,3.95-.75-2.7-2.34-11.21-8.29-14.36-6.36Z" fill="currentColor" />
                            <path d="M121.27,6.28C118,7.8,107,7,107.16,11.28c2.2,3.51,11.31-1.23,15.17-1.16,2.49-.63,1.42-4.57-1.06-3.84Z" fill="currentColor" />
                        </svg>
                    </Box>
                    <Box
                        component="img"
                        src={MyImage}
                        alt="Jothishwar"
                        sx={{
                            width: '80%',
                            objectFit: 'contain',
                            [theme.breakpoints.up('sm')]: {
                                width: '85%',
                            },
                            [theme.breakpoints.up('lg')]:{
                                width:'70%',
                            }
                        }}
                    />
                    <Box
                        className="desc2"
                        sx={{
                            // border:'2px solid red',
                            color:theme.palette.secondary.main,
                            position: 'relative',
                            top: '-60px',
                            left: '-50px',
                            width: '90px',
                            animation: 'slideIn 3s ease-in-out',
                            [theme.breakpoints.up('sm')]: {
                                top: '-60px',
                                left: '-50px',
                                width: '130px',
                            },
                            [theme.breakpoints.up('lg')]: {
                                width: '200px',
                            },
                        }}
                    >
                        <Typography
                            variant="caption"
                            width='inherit'
                            display='block'
                            gutterBottom
                            sx={{
                                fontSize: '0.6rem',
                                marginBottom: '20px',
                                [[theme.breakpoints.up('sm')]]: {
                                    marginBottom:'0px',
                                    fontSize: '0.75rem',
                                },
                                [theme.breakpoints.up('lg')]: {
                                    marginBottom:'20px',
                                    fontSize: '1rem',
                                },
                            }}
                        >
                            I'm not just a still image; I'm a passionate developer who creates interactive experiences!🎭
                        </Typography>
                        <svg className="ml-3 relative" style={{ transform: "rotate(150deg)" }} xmlns="http://www.w3.org/2000/svg" height="10" width="70" viewBox="0 0 123.85 12.57">
                            <path d="M60.09.07A261.23,261.23,0,0,0,15.3,5.13C11.35,6.6,1.76,6.93,0,10.5a1.11,1.11,0,0,0,1.36.77C10.8,7.86,20.79,6.15,30.7,4.76l-.3,0c24.23-3.16,49-3.54,73.16.35a154,154,0,0,1,18.85,4.33,1.15,1.15,0,0,0,1.4-.79c-1.66-3.57-13-4.09-17.11-5.53C91.32.38,75.69-.23,60.09.07Z" fill="currentColor" />
                            <path d="M109.1.57c-.73,3.33,8.28,4.55,10.41,7.11,1.67,2.26,5.58,2.91,3.95-.75-2.7-2.34-11.21-8.29-14.36-6.36Z" fill="currentColor" />
                            <path d="M121.27,6.28C118,7.8,107,7,107.16,11.28c2.2,3.51,11.31-1.23,15.17-1.16,2.49-.63,1.42-4.57-1.06-3.84Z" fill="currentColor" />
                        </svg>
                    </Box>
                </Box>
                <Box
                    className="desc3"
                    sx={{
                        color:theme.palette.secondary.main,
                        display:'flex',
                        position: 'relative',
                        top: '40px',
                        left: '10px',
                        width: '200px',
                        animation: 'fadeIn 5s ease-in-out',
                        [theme.breakpoints.up('sm')]: {
                            top: '-60',
                            left: '-30',
                            width: '250px',
                        },
                        [theme.breakpoints.up('lg')]: {
                            width: '300px',
                        },
                    }}
                >
                    <svg className="ml-3 relative" style={{ transform: "rotate(-115deg)" }} xmlns="http://www.w3.org/2000/svg" height="10" width="70" viewBox="0 0 123.85 12.57">
                        <path d="M60.09.07A261.23,261.23,0,0,0,15.3,5.13C11.35,6.6,1.76,6.93,0,10.5a1.11,1.11,0,0,0,1.36.77C10.8,7.86,20.79,6.15,30.7,4.76l-.3,0c24.23-3.16,49-3.54,73.16.35a154,154,0,0,1,18.85,4.33,1.15,1.15,0,0,0,1.4-.79c-1.66-3.57-13-4.09-17.11-5.53C91.32.38,75.69-.23,60.09.07Z" fill="currentColor" />
                        <path d="M109.1.57c-.73,3.33,8.28,4.55,10.41,7.11,1.67,2.26,5.58,2.91,3.95-.75-2.7-2.34-11.21-8.29-14.36-6.36Z" fill="currentColor" />
                        <path d="M121.27,6.28C118,7.8,107,7,107.16,11.28c2.2,3.51,11.31-1.23,15.17-1.16,2.49-.63,1.42-4.57-1.06-3.84Z" fill="currentColor" />
                    </svg>
                    <Typography
                        variant="caption"
                        width='inherit'
                        display='block'
                        gutterBottom
                        sx={{
                            fontSize: '0.6rem',
                            marginBottom: '20px',
                            [[theme.breakpoints.up('sm')]]: {
                                fontSize: '0.75rem',
                            },
                            [theme.breakpoints.up('lg')]: {
                                fontSize: '1rem',
                            },
                        }}
                    >
                        Behind every successful code is a substantial amount of coffee... and this pink shirt
                    </Typography>
                </Box>
            </Container>
            <Container
                sx={{
                    display: 'none',
                    [theme.breakpoints.up('sm')]: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '0px',
                        width: 'fit-content'
                    },
                    [theme.breakpoints.up('lg')]: {
                        margin: '0px',
                        width: '35%'
                    },
                }}
            >
                <Typography
                    variant="h1"
                    fontFamily={"Square Peg"}
                    sx={{
                        fontSize: '3rem',
                        [theme.breakpoints.up('sm')]: {
                            fontSize: '4rem'
                        },
                        [theme.breakpoints.up('lg')]: {
                            fontSize: '6rem',
                        },
                    }}
                >
                    Hey <br /> I'm &nbsp;<span style={{ color: "#08FDD8" }}>Jo</span>thishwar
                </Typography>
            </Container>
        </Box>
    );
}

export default hero;
