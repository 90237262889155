import { CardMedia, Typography, Box, Button, useMediaQuery, useTheme } from '@mui/material';
import { Parallax } from 'react-scroll-parallax';
import { useInView } from 'react-intersection-observer';

const getRandomTilt = () => {
    return Math.random() * 20 - 10; // Random tilt between -10 and 10 degrees
};

function Project({ project, alignRight }){
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.5,
        easing: 'easeOutQuad',
    });

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const randomTilt = getRandomTilt();

    return (
        <Box
            ref={ref}
            sx={{
                display: 'flex',
                flexDirection: isSmallScreen ? 'column' : alignRight ? 'row-reverse' : 'row',
                alignItems: 'center',
                justifyContent: 'space-around',
                my: 8,
                opacity: inView ? 1 : 0,
                transition: 'opacity 1s',
                [theme.breakpoints.up('sm')]:{
                    width:'90%',
                }
            }}
        >
            <Parallax speed={10}>
                <CardMedia
                    component="img"
                    alt={project.title}
                    image={project.imageUrl}
                    sx={{
                        width: '100%',
                        maxWidth: '300px',
                        height: 'auto',
                        boxShadow: '0 10px 20px rgba(0,0,0,0.3)',
                        transform: `rotate(${randomTilt}deg)`,
                        marginBottom: isSmallScreen ? 2 : 0,
                        marginLeft: isSmallScreen ? 0 : alignRight ? 0 : 4,
                        marginRight: isSmallScreen ? 0 : alignRight ? 4 : 4,
                        transition: 'transform .5s',
                        '&:hover':{
                            transform:'scale(1.1)',
                        }
                    }}
                />
            </Parallax>
            <Box 
                sx={{ 
                    flex: 1, 
                    p: 2, 
                    minWidth: '280px', 
                    ml:4,
                    mr:4,
                    textAlign: isSmallScreen ? 'justify' : 'left',
                }}
            >
                <Typography variant="h6" component="h6">
                    {project.title}
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
                    {project.description}
                </Typography>
                <Box mt={4}>
                    <Button
                        href={project.link}
                        variant="outlined"
                        color="primary"
                        size="medium"
                        target="_blank"
                    >
                        View on GitHub
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default Project;