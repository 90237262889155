import Project2 from './assets/tally_to_hubspot.webp';
import Project1 from './assets/bookedex2.webp';
import Project3 from './assets/webscraping.webp';

const pages = ["Home", "About Me", "My Projects", "Contact Me"];

const events = [
    {
        date: "2018",
        title: "Introduction to Programming",
        description: "My journey into programming began in 11th grade when I chose computer science as my major. I studied Python and MySQL, sparking my interest in the field.",
    },
    {
        date: "2020",
        title: "Discovering Web Development",
        description: "Developed a keen interest in web development and started learning HTML, CSS, and JavaScript through bootcamps and challenges. Started Building several websites to master the basics.",
    },
    {
        date: "2023",
        title: "First Online Internship",
        description: "Started my first online internship at Sparklehood as a Fullstack Engineer Intern. Gained valuable insights and experience, and learned web scraping by writing several scripts.",
    },
    {
        date: "2024",
        title: "Graduated with B.Tech in CSE",
        description: "Graduated with a B.Tech in Computer Science and Engineering from SRM Institute of Science and Technology, achieving an overall CGPA of 9.37.",
    },
    {
        date: "Present",
        title: "Freelance Projects & Continuous Learning",
        description: "Currently focused on learning new technologies and working on freelance projects to further hone my skills.",
    },
];


const skills = [ 'HTML', 'CSS', 'JavaScript', 'ReactJS', 'Python', 'MySQL', 'C++', 'Git', 'CSS Frameworks' ]

const projects = [
    // {
    //     title: "Project One",
    //     description: "This is a description of project one. It's a really cool project that does amazing things.",
    //     imageUrl: "https://via.placeholder.com/600x400",
    //     link: "https://github.com/jothishwar"
    // },
    {
        title: "Book-e-Dex",
        description: "Developed a web application allowing users to browse and purchase books. Leveraged the Google Books API to fetch book details and implemented lazy loading for enhanced performance.",
        imageUrl: Project1,
        link: "https://github.com/Jothishwar/Book-e-Dex"
    },
    {
        title: "Tally-Hubspot Integration API",
        description: "Created an API to integrate Tally form data directly into a HubSpot account. This API automates user addition to HubSpot upon Tally form submission, serving as a cost-effective alternative to Zapier. Tech stack: Python, Flask.",
        imageUrl: Project2,
        link: "https://github.com/Jothishwar/Tally-Hubspot_Integration_API"
    },
    {
        title: "Web Scraping Scripts",
        description: "Developed multiple Python scripts using Selenium and BeautifulSoup libraries to extract job postings from various job portals, storing the data in a database for future use.",
        imageUrl: Project3,
        link: "https://github.com/Jothishwar/shine-scrapper"
    },
];

export {
    pages,
    events,
    skills,
    projects,
};