import React, { useState } from 'react';
import { Container, TextField, Typography, IconButton, Divider, Box, Snackbar, Alert, Grow } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { LinkedIn,Instagram, Twitter, GitHub, Send as SendIcon } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import theme from '../theme';

const ContactPage = () => {
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = React.useState(false);
    const [errorOpen, setErrorOpen] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorOpen(false);
    };
    // function SlideTransition(props) {
    //     return <Slide {...props} direction="up" />;
    // }
    function GrowTransition(props) {
        return <Grow {...props} />;
    }

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            message: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Required'),
            email: Yup.string().email('Invalid email address').required('Required'),
            message: Yup.string().required('Required'),
        }),
        onSubmit: (values, { resetForm }) => {
            setLoading(true);
            console.log(values);
            axios.post('https://portfolio-production-8ded.up.railway.app/send', values)
                .then(response => {
                    setLoading(false);
                    resetForm();
                    setOpen(true);
                })
                .catch(error => {
                    console.log('Error sending Message!', error);
                    setLoading(false);
                    setErrorOpen(true);
                });
        },
    });

    return (
        <Box 
            id="Contact Me"
            sx={{ 
                margin: '60px 0px 40px 0px', 
                display: 'flex', 
                alignItems: 'center', 
                flexDirection: 'column', 
                width:'90vw',
                [theme.breakpoints.up('sm')]:{
                    width:'97vw',
                },
                [theme.breakpoints.up('lg')]:{
                    width:'95vw',
                }
            }}
        >
            <Typography variant="h4" align="center" gutterBottom style={{ marginBottom: '50px', borderBottom:`2px solid ${theme.palette.secondary.main}` }} >
                Contact Me
            </Typography>
            <Container
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'space-around',
                    flexDirection: 'column',
                    [theme.breakpoints.up('sm')]: {
                        flexDirection: 'row',
                    }
                }}>
                <Container
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        [theme.breakpoints.up('sm')]: {
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            flex: 1,
                            width: '50%',
                        }
                    }}>
                    <Typography variant='h6' gutterBottom sx={{ borderBottom:`1px solid ${theme.palette.secondary.main}`, width: 'fit-content' }} >Let's Talk</Typography>
                    <Typography variant='body1' align='justify' gutterBottom sx={{ marginBottom: '15px' }} >Got a project in mind or need a digital solution? I'd love to hear from you! Whether it's a new website, a brand makeover, or a unique idea you're passionate about, let's discuss how we can bring it to life together.</Typography>
                    <Typography variant='h6' gutterBottom sx={{ borderBottom:`1px solid ${theme.palette.secondary.main}`, width: 'fit-content' }} >Email</Typography>
                    <Typography variant='body1' gutterBottom sx={{ marginBottom: '20px' }} >jothishwarvvr@gmail.com</Typography>

                    <Typography variant='h6' gutterBottom sx={{ borderBottom:`1px solid ${theme.palette.secondary.main}`, width: 'fit-content' }} >Social Handles</Typography>
                    <div style={{ display: 'flex', marginBottom: '10px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '5px' }} >
                            <IconButton
                                area-label='Click here to view my Github profile'
                                href="https://github.com/jothishwar"
                                target="_blank"
                                sx={{
                                    color: '#ccc',
                                    alignSelf: 'flex-start',
                                    paddingLeft: 0,
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                    },
                                }}
                            >
                                <GitHub />
                            </IconButton>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '5px' }} >
                            <IconButton
                                area-label='Click here to view my LinkedIn profile'
                                href="https://www.linkedin.com/in/jothishwar"
                                target="_blank"
                                sx={{
                                    color: '#ccc',
                                    alignSelf: 'flex-start',
                                    paddingLeft: 0,
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                    },
                                }}
                            >
                                <LinkedIn />
                            </IconButton>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '5px' }} >
                            <IconButton
                                area-label='Click here to view my Twitter profile'
                                href="https://x.com/Jothishwar_s"
                                target="_blank"
                                sx={{
                                    color: '#ccc',
                                    alignSelf: 'flex-start',
                                    paddingLeft: 0,
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                    },
                                }}
                            >
                                <Twitter />
                            </IconButton>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '5px' }} >
                            <IconButton
                                area-label='Click here to view my Instagram profile'
                                href="https://www.instagram.com/jothishwar06/"
                                target="_blank"
                                sx={{
                                    color: '#ccc',
                                    alignSelf: 'flex-start',
                                    paddingLeft: 0,
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                    },
                                }}
                            >
                                <Instagram />
                            </IconButton>
                        </div>
                    </div>
                </Container>
                <Divider orientation="vertical" flexItem sx={{ display: 'none', backgroundColor: '#ccc', width: '0.75px', margin: '0 20px', [theme.breakpoints.up('sm')]: { display: 'flex' } }} />
                <Container sx={{ flex: 1 }}>
                    <Typography variant='h6' gutterBottom sx={{ borderBottom:`1px solid ${theme.palette.secondary.main}`, width: 'fit-content' }} >Send Me a Message</Typography>
                    <form onSubmit={formik.handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
                        <TextField
                            label="Your Name"
                            variant="filled"
                            fullWidth
                            margin="normal"
                            name="name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                            InputProps={{
                                style: { backgroundColor: '#222', color: '#ccc' }
                            }}
                            InputLabelProps={{
                                style: { color: '#ccc' }
                            }}
                        />
                        <TextField
                            label="Your Email"
                            variant="filled"
                            fullWidth
                            margin="normal"
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                            InputProps={{
                                style: { backgroundColor: '#222', color: '#ccc' }
                            }}
                            InputLabelProps={{
                                style: { color: '#ccc' }
                            }}
                        />
                        <TextField
                            label="Your Message"
                            variant="filled"
                            fullWidth
                            margin="normal"
                            multiline
                            rows={4}
                            name="message"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.message}
                            error={formik.touched.message && Boolean(formik.errors.message)}
                            helperText={formik.touched.message && formik.errors.message}
                            InputProps={{
                                style: { backgroundColor: '#222', color: '#ccc' }
                            }}
                            InputLabelProps={{
                                style: { color: '#ccc' }
                            }}
                        />

                        <LoadingButton
                            loading={loading}
                            loadingPosition='start'
                            startIcon={<SendIcon fontSize='small' />}
                            variant='contained'
                            color='primary'
                            type='submit'

                            sx={{
                                marginTop: '20px',
                                alignSelf: 'flex-start'
                            }}
                        >
                            Send
                        </LoadingButton>
                    </form>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={open}
                        autoHideDuration={3000}
                        onCloseq={handleClose}
                        TransitionComponent={GrowTransition}
                        sx={{
                            marginTop:'60px',
                        }}
                        
                    >
                        <Alert
                            onClose={handleClose}
                            severity="success"
                            variant="filled"
                            sx={{ fontWeight:600, }}
                        >
                            Message Sent Successfully!
                        </Alert>
                    </Snackbar>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={errorOpen}
                        autoHideDuration={3000}
                        onClose={handleErrorClose}
                        TransitionComponent={GrowTransition}
                        sx={{
                            marginTop:'60px',
                        }}
                        
                    >
                        <Alert
                            onClose={handleErrorClose}
                            severity="error"
                            variant="filled"
                            sx={{ fontWeight:600, }}
                        >
                            Error Sending Message, Try Again!
                        </Alert>
                    </Snackbar>
                </Container>
            </Container>
        </Box>
    );
};

export default ContactPage;
