import React from 'react';
import { Typography, Box, Button} from '@mui/material';
import { ParallaxProvider } from 'react-scroll-parallax';
import Project from '../components/Project';
import theme from "../theme";
import {projects} from '../const';

const Projects = () => {
    return (
        <ParallaxProvider>
            <Box 
                id='My Projects' 
                sx={{ 
                    // border:'2px solid red',
                    width:'90vw',
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    alignItems:'center',
                    [theme.breakpoints.up('sm')]:{
                        width:'97vw',
                    },
                    [theme.breakpoints.up('lg')]:{
                        width:'95vw',
                    }
                }}
            >
                <Typography variant="h4" gutterBottom align="center" sx={{ borderBottom:`2px solid ${theme.palette.secondary.main}`, width:'fit-content' }} >
                    My Projects
                </Typography>
                {projects.map((project, index) => (
                    <Project
                        key={index}
                        project={project}
                        alignRight={index % 2 === 0}
                    />
                ))}
                <Box textAlign="center" mt={4}>
                    <Button
                        href="https://github.com/jothishwar"
                        variant="contained"
                        color="primary"
                        size="large"
                        target="_blank"
                    >
                        See More on GitHub
                    </Button>
                </Box>
            </Box>
        </ParallaxProvider>
    );
};

export default Projects;