import { useState, useEffect } from 'react';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline, Container } from '@mui/material';
import Navbar from './components/Navbar';
import Hero from './pages/hero';
import About from './pages/about';
import Projects from './pages/Projects';
import Contact from './pages/ContactMe';
import SpeedDial from './components/SpeedDial';
import theme from './theme';
import Loader from './components/Loader';

function App() {
  const [loading, setLoading] = useState(true);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    const handleLoad = () => {
      setLoading(false);
    };

    if (document.readyState === 'complete') {
      handleLoad();
    } else {
      window.addEventListener('load', handleLoad);
      return () => window.removeEventListener('load', handleLoad);
    }
  }, []);

  const handleImagesLoad = () => {
    setImagesLoaded(true);
  };

  useEffect(() => {
    const images = document.querySelectorAll('img');
    const totalImages = images.length;
    let imagesLoadedCount = 0;

    images.forEach((img) => {
      if (img.complete) {
        imagesLoadedCount++;
      } else {
        img.addEventListener('load', () => {
          imagesLoadedCount++;
          if (imagesLoadedCount === totalImages) {
            handleImagesLoad();
          }
        });
        img.addEventListener('error', () => {
          imagesLoadedCount++;
          if (imagesLoadedCount === totalImages) {
            handleImagesLoad();
          }
        });
      }
    });

    if (imagesLoadedCount === totalImages) {
      handleImagesLoad();
    }
  }, []);

  if (loading || !imagesLoaded) {
    return <Loader />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="main-container">
        <Navbar />
        <Container
          sx={{
            marginTop: '70px',
            [theme.breakpoints.up('sm')]: {
              padding: '0px 10px'
            },
            [theme.breakpoints.up('lg')]: {
              margin: '0px 20px'
            }
          }}
        >
          <Hero />
          <About />
          <Projects />
          <Contact />
        </Container>
        <SpeedDial />
      </div>
    </ThemeProvider>
  );
}

export default App;
