import React from "react";
import { Typography, Box, Button, Chip } from "@mui/material";
import AnchorLink from "react-anchor-link-smooth-scroll";
import MyTimeline from "../components/MyTimeline";
import theme from "../theme";
import { events,skills } from '../const';

function About() {
    return (
        <Box id="About Me" 
            sx={{ 
                width:'90vw',
                marginTop: '80px', 
                marginBottom: '50px',
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignItems:'center',
                // border:'2px solid cyan',
                [theme.breakpoints.up('sm')]:{
                    width:'97vw',
                },
                [theme.breakpoints.up('lg')]:{
                    width:'95vw',
                }
            }}
        >
            <Typography 
                variant="h4" 
                align="center" 
                gutterBottom 
                sx={{ 
                    borderBottom:`2px solid ${theme.palette.secondary.main}`,
                    width:'fit-content',
                    marginBottom:'40px',
                }} 
                >
                About Me
            </Typography>
            <Box 
                sx={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                }}
            >
                <Box
                    sx={{
                        maxWidth:'95%',
                        [theme.breakpoints.up('sm')]:{
                            maxWidth:'85%',
                        }
                    }}
                >
                    <Typography variant="body1" align="justify" >
                        Hi there! I'm Jothishwar, a passionate and creative web developer based in India. I'm not just your average web developer – I'm a code artist on a mission to transform pixels into digital magic! With a blend of creativity, passion, and a touch of caffeine, I craft immersive digital experiences that captivate and inspire. Let's build something awesome together! 💻✨
                    </Typography><br />
                    <Typography variant="body1" align="justify" >
                    When I'm not coding, you can find me watching Japanese anime—feel free to connect with me to chat about your favorite series! Besides that, I enjoy playing Volleyball, Kabaddi, and Football, but I'm always open to trying out other sports and games too.
                    </Typography>
                    <Box mt={2}>
                        <Typography variant="subtitle1" gutterBottom >
                            My Skills :
                        </Typography>
                        <Box>
                            {skills.map((skill) => {
                                return(
                                        <Chip label={skill} key={skill} color="secondary" variant="outlined" style={{ margin: '4px' }} />
                                )}
                            )}
                        </Box>
                    </Box>
                    <Box mt={2}>
                        <AnchorLink href="#Contact Me">
                            <Button variant="contained" color="primary">
                                Contact Me
                            </Button>
                        </AnchorLink>
                    </Box>
                    <Box mt={4}>
                        <Typography variant="h6" align="center" gutterBottom sx={{ borderBottom:`1px solid ${theme.palette.secondary.main}`, width:'fit-content' }}>
                            My Journey
                        </Typography>
                        <MyTimeline events={events} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default About;
